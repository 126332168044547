import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PanelService } from '../../services/panel.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { PropietarioService } from '../../services/propietario.service';
import { PotreroService } from 'src/app/services/potrero.service';
import { CategoriaService } from '../../services/categoria.service';
import { LoteService } from '../../services/lote.service';
import { AnimalService } from 'src/app/services/animal.service';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
const moment = _moment;


export interface Carimbo {
  valor: string;
}

export interface Mes {
  id: string;
  descripcion: string;
}

@Component({
  selector: 'app-sidenav-filter',
  templateUrl: './sidenav-filter.component.html',
  styleUrls: ['./sidenav-filter.component.scss']
})
export class SidenavFilterComponent implements OnInit {

  date = new FormControl(moment());

  // Filtro ide
  ideList: string[] = [];

  // Filtro ide
  idvList: string[] = [];

  // Filtro propietarios
  propietarios = [];
  propietariosSeleccionados: any[] = [];
  propietariosFiltrados: Observable<any[]>;
  propietarioCtrl = new FormControl();

  // Filtro potreros
  potreros = [];
  potrerosSeleccionados: any[] = [];
  potrerosFiltrados: Observable<any[]>;
  potreroCtrl = new FormControl();

  // Filtro categorias
  categorias = [];
  categoriasSeleccionadas: string[] = [];
  categoriasFiltradas: Observable<any[]>;
  categoriaCtrl = new FormControl();

  // Filtro clasificaciones
  clasificaciones = [];
  clasificacionesSeleccionadas: string[] = [];
  clasificacionesFiltradas: Observable<any[]>;
  clasificacionesCtrl = new FormControl();

  // Filtro sitrap
  sitrap = [];
  sitrapSeleccionados: string[] = [];
  sitrapFiltrados: Observable<any[]>;
  sitrapCtrl = new FormControl();
  
  // Filtro lotes
  lotes = [];
  lotesSeleccionados: string[] = [];
  lotesFiltrados: Observable<any[]>;
  loteCtrl = new FormControl();

  // Filtro madre_idvs
  madre_idvs = [];
  madre_idvsSeleccionados: string[] = [];
  madre_idvsFiltrados: Observable<any[]>;
  madre_idvCtrl = new FormControl();

  // Filtro de año
  anho: Date;

  // Filtro de mes
  meses: Mes[] = [
    {id: '1', descripcion: 'ENERO'},
    {id: '2', descripcion: 'FEBRERO'},
    {id: '3', descripcion: 'MARZO'},
    {id: '4', descripcion: 'ABRIL'},
    {id: '5', descripcion: 'MAYO'},
    {id: '6', descripcion: 'JUNIO'},
    {id: '7', descripcion: 'JULIO'},
    {id: '8', descripcion: 'AGOSTO'},
    {id: '9', descripcion: 'SETIEMBRE'},
    {id: '10', descripcion: 'OCTUBRE'},
    {id: '11', descripcion: 'NOVIEMBRE'},
    {id: '12', descripcion: 'DICIEMBRE'}
  ];
  mesesSeleccionados: Mes[] = [];
  mesesFiltrados: Observable<any[]>;
  mesCtrl = new FormControl();

  // Filtro rango de fecha
  rangeForm = new FormGroup({
    date_from: new FormControl(),
    date_to: new FormControl(),
  });
  maxDate: Date;
  minDate: Date;

  // Filtro sexos
  sexos = ['MACHO', 'HEMBRA'];
  sexosSeleccionados: string[] = [];
  sexosFiltrados: Observable<any[]>;
  sexoCtrl = new FormControl();

  // Filtro padre y carimbo
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  padres: string[] = [];
  carimbos: Carimbo[] = [];

  // Filtro estados
  estados: any[] = [
    {id: 'V', descripcion: 'ACTIVO'},
    {id: 'M', descripcion: 'MORTANDAD'},
    {id: 'S', descripcion: 'SALIDA'},
    {id: 'C', descripcion: 'CONSUMO'},
  ];
  estadosSeleccionados: string[] = [];
  estadosFiltrados: Observable<any[]>;
  estadoCtrl = new FormControl();

  @ViewChild('propietarioInput') propietarioInput: ElementRef<HTMLInputElement>;
  @ViewChild('potreroInput') potreroInput: ElementRef<HTMLInputElement>;
  @ViewChild('categoriaInput') categoriaInput: ElementRef<HTMLInputElement>;
  @ViewChild('clasificacionInput') clasificacionInput: ElementRef<HTMLInputElement>;
  @ViewChild('sitrapInput') sitrapInput: ElementRef<HTMLInputElement>;
  @ViewChild('loteInput') loteInput: ElementRef<HTMLInputElement>;
  @ViewChild('madre_idvInput') madre_idvInput: ElementRef<HTMLInputElement>;
  @ViewChild('sexoInput') sexoInput: ElementRef<HTMLInputElement>;
  @ViewChild('mesInput') mesInput: ElementRef<HTMLInputElement>;
  @ViewChild('estadoInput') estadoInput: ElementRef<HTMLInputElement>;
  @Output() filtrosChange = new EventEmitter<any>();
  @Input()  filtros;

  constructor(
    private panelService: PanelService,
    private fb: FormBuilder,
    private propietarioService: PropietarioService,
    private potreroService: PotreroService,
    private categoriaService: CategoriaService,
    private loteService: LoteService,
    private animalService: AnimalService
    ) {
    this.maxDate = new Date();
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 12, 99);
  }

  ngOnInit(): void {
    this.propietarioService.getList().subscribe( data => {
      this.propietarios = data;
      this.propietariosFiltrados = this.propietarioCtrl.valueChanges.pipe(
        startWith(null),
        map((propietario: any | null) => (propietario ? this._filterPropietario(propietario) : this.getPropietarios())),
      );
    });
    this.potreroService.getList().subscribe( data => {
      this.potreros = data;
      this.potrerosFiltrados = this.potreroCtrl.valueChanges.pipe(
        startWith(null),
        map((potrero: any | null) => (potrero ? this._filterPotrero(potrero) : this.getPotreros())),
      );
    });
    this.categoriaService.getList().subscribe( data => {
      this.categorias = data;
      this.categoriasFiltradas = this.categoriaCtrl.valueChanges.pipe(
        startWith(null),
        map((categoria: any | null) => (categoria ? this._filterCategoria(categoria) : this.getCategorias())),
      );
    });
    this.animalService.getListClasificacion().subscribe( data => {
      this.clasificaciones = data;
      this.clasificacionesFiltradas = this.clasificacionesCtrl.valueChanges.pipe(
        startWith(null),
        map((clasificacion: any | null) => (clasificacion ? this._filterClasificacion(clasificacion) : this.getClasificaciones())),
      );
    });
    this.animalService.getListSitrap().subscribe( data => {
      this.sitrap = data;
      this.sitrapFiltrados = this.sitrapCtrl.valueChanges.pipe(
        startWith(null),
        map((sitrap: any | null) => (sitrap ? this._filterSitrap(sitrap) : this.getSitrap())),
      );
    });
    this.loteService.getList().subscribe( data => {
      this.lotes = data;
      this.lotesFiltrados = this.loteCtrl.valueChanges.pipe(
        startWith(null),
        map((lote: any | null) => (lote ? this._filterLote(lote) : this.getLotes())),
      );
    });
    this.animalService.getListParents().subscribe( data => {
      this.madre_idvs = data;
      this.madre_idvsFiltrados = this.madre_idvCtrl.valueChanges.pipe(
        startWith(null),
        map((madre_idv: any | null) => (madre_idv ? this._filterMadre_idv(madre_idv) : this.getMadre_idvs())),
      );
    });
    this.sexosFiltrados = this.sexoCtrl.valueChanges.pipe(
      startWith(null),
      map((sexo: string | null) => (sexo ? this._filterSexo(sexo) : this.sexos.slice())),
    );
    this.mesesFiltrados = this.mesCtrl.valueChanges.pipe(
      startWith(null),
      map((mes: string | null) => (mes ? this._filterMes(mes) : this.getMeses())),
    );
    this.estadosFiltrados = this.estadoCtrl.valueChanges.pipe(
      startWith(null),
      map((estado: string | null) => (estado ? this._filterEstado(estado) : this.estados.slice())),
    );
    this.loadFilter();
  }

  private loadFilter() {
    if (this.filtros.ideList && this.filtros.ideList.length > 0) {
      this.ideList = this.filtros.ideList.slice();
    }
    if (this.filtros.idvList && this.filtros.idvList.length > 0) {
      this.idvList = this.filtros.idvList.slice();
    }
    if (this.filtros.propietarios && this.filtros.propietarios.length > 0) {
      this.propietariosSeleccionados = this.filtros.propietarios.slice();
    }
    if (this.filtros.potreros && this.filtros.potreros.length > 0) {
      this.potrerosSeleccionados = this.filtros.potreros.slice();
    }
    if (this.filtros.categorias && this.filtros.categorias.length > 0) {
      this.categoriasSeleccionadas = this.filtros.categorias.slice();
    }
    if (this.filtros.carimbos && this.filtros.carimbos.length > 0) {
      this.carimbos = this.filtros.carimbos.slice();
    }
    if (this.filtros.clasificaciones && this.filtros.clasificaciones.length > 0) {
      this.clasificacionesSeleccionadas = this.filtros.clasificaciones.slice();
    }
    if (this.filtros.sitrap && this.filtros.sitrap.length > 0) {
      this.sitrapSeleccionados = this.filtros.sitrap.slice();
      console.log( this.sitrapSeleccionados);
    }
    if (this.filtros.anho) {
      this.anho = this.filtros.anho;
    }
    if (this.filtros.meses && this.filtros.meses.length > 0) {
      this.mesesSeleccionados = this.filtros.meses.slice();
    }
    if (this.filtros.date_from) {
      this.rangeForm.get('date_from').setValue(this.filtros.date_from);
    }
    if (this.filtros.date_to) {
      this.rangeForm.get('date_to').setValue(this.filtros.date_to);
    }
    if (this.filtros.lotes && this.filtros.lotes.length > 0) {
      this.lotesSeleccionados = this.filtros.lotes.slice();
    }
    if (this.filtros.madre_idvs && this.filtros.madre_idvs.length > 0) {
      this.madre_idvsSeleccionados = this.filtros.madre_idvs.slice();
    }
    if (this.filtros.sexos && this.filtros.sexos.length > 0) {
      this.sexosSeleccionados = this.filtros.sexos.slice();
    }
    if (this.filtros.padres && this.filtros.padres.length > 0) {
      this.padres = this.filtros.padres.slice();
    }
    if (this.filtros.estados && this.filtros.estados.length > 0) {
      this.estadosSeleccionados = this.filtros.estados.slice();
    }
  }

  closeSideNavFilter() {
    this.panelService.close();
  }

  addIde(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our ide
    if (value) {
      this.ideList.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  addIdv(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our ide
    if (value) {
      this.idvList.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  addPadre(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our padre
    if (value) {
      this.padres.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  addCarimbo(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our carimbo
    if (value) {
      this.carimbos.push({valor: value});
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  addClasificacion(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our carimbo
    if (value) {
      this.clasificaciones.push( value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  removeIde(ide: string): void {
    const index = this.ideList.indexOf(ide);

    if (index >= 0) {
      this.ideList.splice(index, 1);
    }
  }

  removeIdv(idv: string): void {
    const index = this.idvList.indexOf(idv);

    if (index >= 0) {
      this.idvList.splice(index, 1);
    }
  }

  removePadre(padre: string): void {
    const index = this.padres.indexOf(padre);

    if (index >= 0) {
      this.padres.splice(index, 1);
    }
  }

  removeCarimbo(carimbo: Carimbo): void {
    const index = this.carimbos.indexOf(carimbo);

    if (index >= 0) {
      this.carimbos.splice(index, 1);
    }
  }

  removeClasificacion(clasificacion: string): void {
    const index = this.clasificacionesSeleccionadas.indexOf(clasificacion);

    if (index >= 0) {
      this.clasificaciones.splice(index, 1);
      this.clasificacionesSeleccionadas.splice(index, 1);
      this.clasificacionesCtrl.setValue(null);
    }
  }

  removeSitrap(sitrap: string): void {
    const index = this.sitrapSeleccionados.indexOf(sitrap);

    if (index >= 0) {
      this.sitrap.splice(index, 1);
      this.sitrapSeleccionados.splice(index, 1);
      this.sitrapCtrl.setValue(null);
    }
  }
  
  removePropietario(propietario: any): void {
    const index = this.propietariosSeleccionados.indexOf(propietario);

    if (index >= 0) {
      this.propietariosSeleccionados.splice(index, 1);
      this.propietarioCtrl.setValue(null);
    }
  }
  
  removePotrero(potrero: any): void {
    const index = this.potrerosSeleccionados.indexOf(potrero);

    if (index >= 0) {
      this.potrerosSeleccionados.splice(index, 1);
      this.potreroCtrl.setValue(null);
    }
  }

  removeCategoria(categoria: any): void {
    const index = this.categoriasSeleccionadas.indexOf(categoria);

    if (index >= 0) {
      this.categoriasSeleccionadas.splice(index, 1);
      this.categoriaCtrl.setValue(null);
    }
  }

  removeLote(lote: any): void {
    const index = this.lotesSeleccionados.indexOf(lote);

    if (index >= 0) {
      this.lotesSeleccionados.splice(index, 1);
      this.loteCtrl.setValue(null);
    }
  }

  removeMadre_idv(madre_idv: any): void {
    const index = this.madre_idvsSeleccionados.indexOf(madre_idv);

    if (index >= 0) {
      this.madre_idvsSeleccionados.splice(index, 1);
      this.madre_idvCtrl.setValue(null);
    }
  }

  removeSexo(sexo: any): void {
    const index = this.sexosSeleccionados.indexOf(sexo);

    if (index >= 0) {
      this.sexosSeleccionados.splice(index, 1);
      this.sexoCtrl.setValue(null);
    }
  }

  removeMes(mes: any): void {
    const index = this.mesesSeleccionados.indexOf(mes);

    if (index >= 0) {
      this.mesesSeleccionados.splice(index, 1);
      this.mesCtrl.setValue(null);
    }
  }

  removeEstado(estado: any): void {
    const index = this.estadosSeleccionados.indexOf(estado);

    if (index >= 0) {
      this.estadosSeleccionados.splice(index, 1);
      this.estadoCtrl.setValue(null);
    }
  }

  selectedPropietario(event: MatAutocompleteSelectedEvent): void {
    this.propietariosSeleccionados.push(event.option.value);
    this.propietarioInput.nativeElement.value = '';
    this.propietarioCtrl.setValue(null);
  }

  selectedPotrero(event: MatAutocompleteSelectedEvent): void {
    this.potrerosSeleccionados.push(event.option.value);
    this.potreroInput.nativeElement.value = '';
    this.potreroCtrl.setValue(null);
  }

  selectedCategoria(event: MatAutocompleteSelectedEvent): void {
    this.categoriasSeleccionadas.push(event.option.value);
    this.categoriaInput.nativeElement.value = '';
    this.categoriaCtrl.setValue(null);
  }

  selectedClasificacion(event: MatAutocompleteSelectedEvent): void {
    this.clasificacionesSeleccionadas.push(event.option.value);
    this.clasificacionInput.nativeElement.value = '';
    this.clasificacionesCtrl.setValue(null);
  }

  selectedSitrap(event: MatAutocompleteSelectedEvent): void {
    this.sitrapSeleccionados.push(event.option.value);
    this.sitrapInput.nativeElement.value = '';
    this.sitrapCtrl.setValue(null);
  }

  selectedLote(event: MatAutocompleteSelectedEvent): void {
    this.lotesSeleccionados.push(event.option.value);
    this.loteInput.nativeElement.value = '';
    this.loteCtrl.setValue(null);
  }

  selectedMadre_idv(event: MatAutocompleteSelectedEvent): void {
    this.madre_idvsSeleccionados.push(event.option.value);
    this.madre_idvInput.nativeElement.value = '';
    this.madre_idvCtrl.setValue(null);
  }

  selectedSexo(event: MatAutocompleteSelectedEvent): void {
    this.sexosSeleccionados.push(event.option.value);
    this.sexoInput.nativeElement.value = '';
    this.sexoCtrl.setValue(null);
  }

  selectedMes(event: MatAutocompleteSelectedEvent): void {
    this.mesesSeleccionados.push(event.option.value);
    this.mesInput.nativeElement.value = '';
    this.mesCtrl.setValue(null);
  }

  selectedEstado(event: MatAutocompleteSelectedEvent): void {
    this.estadosSeleccionados.push(event.option.value);
    this.estadoInput.nativeElement.value = '';
    this.estadoCtrl.setValue(null);
  }

  private _filterPropietario(value: any): string[] {
    if (value && value.id == null) {
      const filterValue = value.toLowerCase();
      return this.propietarios.filter(propietario => propietario.descripcion.toLowerCase().includes(filterValue));
    }
  }

  private _filterPotrero(value: any): string[] {
    if (value && value.id == null) {
      const filterValue = value.toLowerCase();
      return this.potreros.filter(potrero => potrero.descripcion.toLowerCase().includes(filterValue));
    }
  }

  private _filterCategoria(value: any): string[] {
    if (value && value.id == null) {
      const filterValue = value.toLowerCase();
      return this.categorias.filter(categoria => categoria.descripcion.toLowerCase().includes(filterValue));
    }
  }

  private _filterClasificacion(value: any): string[] {
    if (value && value.id == null) {
      const filterValue = value.toLowerCase();
      return this.clasificaciones.filter(clasificacion => clasificacion.toLowerCase().includes(filterValue));
    }
  }

  private _filterSitrap(value: any): string[] {
    if (value && value.id == null) {
      const filterValue = value.toLowerCase();
      return this.sitrap.filter(sitrap => sitrap.toLowerCase().includes(filterValue));
    }
  }

  private _filterLote(value: any): string[] {
    if (value && value.id == null) {
      const filterValue = value.toLowerCase();
      return this.lotes.filter(lote => lote.descripcion.toLowerCase().includes(filterValue));
    }
  }

  private _filterMadre_idv(value: any): string[] {
    if (value && value.id == null) {
      const filterValue = value.toLowerCase();
      return this.madre_idvs.filter(madre_idv => madre_idv.toLowerCase().includes(filterValue));
    }
  }

  private _filterSexo(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.sexos.filter(sexo => sexo.toLowerCase().includes(filterValue));
  }

  private _filterMes(value: any): Mes[] {
    if (value && value.id == null) {
      const filterValue = value.toLowerCase();
      return this.meses.filter(mes => mes.descripcion.toLowerCase().includes(filterValue));
    }
  }

  private _filterEstado(value: any): string[] {
    if (value && value.id == null) {
      const filterValue = value.toLowerCase();
      return this.estados.filter(estado => estado.descripcion.toLowerCase().includes(filterValue));
    }
  }

  private getPropietarios() {
    return this.propietarios.filter(item => !this.propietariosSeleccionados.includes(item));
  }

  private getPotreros() {
    return this.potreros.filter(item => !this.potrerosSeleccionados.includes(item));
  }

  private getCategorias() {
    return this.categorias.filter(item => !this.categoriasSeleccionadas.includes(item));
  }

  private getClasificaciones() {
    return this.clasificaciones.filter(item => !this.clasificacionesSeleccionadas.includes(item));
  }

  private getSitrap() {
    return this.sitrap.filter(item => !this.sitrapSeleccionados.includes(item));
  }

  private getLotes() {
    return this.lotes.filter(item => !this.lotesSeleccionados.includes(item));
  }

  private getMadre_idvs() {
    return this.madre_idvs.filter(item => !this.madre_idvsSeleccionados.includes(item));
  }

  private getMeses() {
    return this.meses.filter(item => !this.mesesSeleccionados.includes(item));
  }

  filtrar() {
    this.filtros.ideList = this.ideList.slice();
    this.filtros.idvList = this.idvList.slice();
    this.filtros.propietarios = this.propietariosSeleccionados.slice();
    this.filtros.potreros = this.potrerosSeleccionados.slice();
    this.filtros.categorias = this.categoriasSeleccionadas.slice();
    this.filtros.carimbos = this.carimbos.slice();
    this.filtros.clasificaciones = this.clasificacionesSeleccionadas.slice();
    this.filtros.sitrap = this.sitrapSeleccionados.slice();
    this.filtros.anho = this.anho ? this.anho : null;
    this.filtros.meses = this.mesesSeleccionados.slice();
    this.filtros.date_from = this.rangeForm.get('date_from').value;
    this.filtros.date_to = this.rangeForm.get('date_to').value;
    this.filtros.lotes = this.lotesSeleccionados.slice();
    this.filtros.madre_idvs = this.madre_idvsSeleccionados.slice();
    this.filtros.sexos = this.sexosSeleccionados.slice();
    this.filtros.padres = this.padres.slice();
    this.filtros.estados = this.estadosSeleccionados.slice();
    this.filtrosChange.emit(this.filtros);
    this.closeSideNavFilter();
  }

}
