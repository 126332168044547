import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root',
})
export class CategoriaService extends BaseService {
  url = `${this.apiUrl}categoria`;

  constructor(private http: HttpClient) {
    super();
  }

  getCategoria(categoriaId: number): Observable<any> {
    const categoria = categoriaId.toString();
    return this.http.get<any>(`${this.url}/${categoria}`);
  }

  getList(): Observable<any[]> {
    return this.http.get<any[]>(`${this.url}`);
  }

  getPaginate(skip: number = 0, limit: number = 0, sortBy: string, direction: string): Observable<any[]> {
    let toGet = `${this.url}/paginate/?skip=${skip}&limit=${limit}`;
    toGet = sortBy && direction ? toGet.concat(`&sort_by=${sortBy}&direction=${direction}`) : toGet;
    return this.http.get<any[]>(toGet);
  }

  saveCategoria(formData): Observable<any> {
    return this.http.post<any>(`${this.url}/`, formData);
  }

  updateCategoria(categoriaId: number, formData): Observable<any> {
    return this.http.put<any>(`${this.url}/${categoriaId}`, formData);
  }

  activeInactiveCategoria(categoriaId: number): Observable<any> {
    return this.http.patch<any>(`${this.url}/${categoriaId}`, {});
  }

}
