<mat-toolbar color="primary">
  <button class="btn-expander" mat-icon-button (click)="toggleSidebarMenu()">
    <mat-icon class="menu">menu</mat-icon>
  </button>
  <a class="logo-container" [routerLink]="['/']">
    <img class="logo" src="assets/img/CattleTrackingfondoazul.png" alt="Cattle Traking" />
  </a>
  <div class="spacer">
    <ng-content></ng-content>
  </div>
  <span class="user">
    {{ currentUser }}
  </span>
  <ng-container>
    <button mat-button class="avatar-container" [matMenuTriggerFor]="profileMenu">
      <mat-icon class="material-icons-account">account_circle</mat-icon>
    </button>
    <mat-menu #profileMenu="matMenu" xPosition="before">
      <span class="button">
        <button mat-menu-item (click)="logout()">Cerrar sesión</button>
      </span>
    </mat-menu>
  </ng-container>
</mat-toolbar>
