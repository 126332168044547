<div class="sidenav">
  <img src="../../../assets/img/Cattletrackinglogologin.png" alt="Logo de aplicación">
</div>
<div class="main">
  <div class="col-md-4 col-sm-12 text-center">
    <form [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()">
      <mat-card class="login-card" *ngIf="loginCard">
        <mat-card-header class="justify-content-center">
          <mat-card-title>
            <h1 class="form-title">Bienvenido/a</h1>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-form-field class="full-width round">
            <mat-label class="form_label">Usuario</mat-label>
            <input matInput placeholder="Usuario" formControlName="username">
            <mat-error *ngIf="loginForm.controls['username'].hasError('required')">
              El usuario es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width round">
            <input matInput placeholder="Contraseña" formControlName="password"
                   [type]="showPassword ? 'text' : 'password'"/>
            <button type="button" matSuffix mat-icon-button (click)="showPassword = !showPassword">
              <mat-icon>
                {{showPassword ? "visibility_off" : "visibility"}}
              </mat-icon>
            </button>
            <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
              La contraseña es <strong>requerida</strong>
            </mat-error>
            <mat-hint align="end">
              <a [routerLink]="" (click)="showSendCodeCard()">¿Olvidaste tu contraseña?</a>
            </mat-hint>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions class="row">
          <button mat-flat-button class="col" color="primary" type="submit">INICIAR SESIÓN</button>
        </mat-card-actions>
      </mat-card>  
    </form>
    <form [formGroup]="codeForm" novalidate>
      <mat-card class="login-card" *ngIf="sendCodeCard">
        <mat-card-header class="justify-content-center">
          <mat-card-title>
            <h1 class="form-title">Reestablecer Contraseña</h1>
          </mat-card-title>
        </mat-card-header>
        <mat-label class="form_label" style="padding-bottom: 10px;">Enviaremos un Código de 6 digitos al correo para reestablecer la contraseña</mat-label>
        <mat-card-content>
          <mat-form-field class="full-width round">
            <mat-label class="form_label">Correo</mat-label>
            <input matInput placeholder="Usuario" formControlName="email">
            <mat-error *ngIf="codeForm.controls['email'].hasError('required')">
              El usuario es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions class="row">
          <button mat-flat-button class="col" color="primary" (click)="showPasswordCard()" [disabled]="!codeForm.valid">Enviar Código</button>
        </mat-card-actions>
      </mat-card>
    </form>
    <form [formGroup]="newPasswordForm" novalidate>
      <mat-card class="login-card" *ngIf="forgotPasswordCard">
        <mat-card-header class="justify-content-center">
          <mat-card-title>
            <h1 class="form-title">Reestablecer Contraseña</h1>
          </mat-card-title>
        </mat-card-header>
        <mat-label class="form_label" style="padding-bottom: 10px;">El código tiene una validez de 5 minutos</mat-label>
        <mat-card-content>
          <mat-form-field class="full-width round">
            <mat-label class="form_label">Código</mat-label>
            <input matInput placeholder="Código" formControlName="code">
            <mat-error *ngIf="newPasswordForm.controls['code'].hasError('required')">
              El código es <strong>requerido</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width round">
            <input matInput placeholder="Nueva Contraseña" formControlName="new_password"
                   [type]="showPassword ? 'text' : 'password'"/>
            <button type="button" matSuffix mat-icon-button (click)="showPassword = !showPassword">
              <mat-icon>
                {{showPassword ? "visibility_off" : "visibility"}}
              </mat-icon>
            </button>
            <mat-error *ngIf="newPasswordForm.controls['new_password'].hasError('required')">
              La contraseña es <strong>requerida</strong>
            </mat-error>
          </mat-form-field>
          <mat-hint align="end">
            <a [routerLink]="" (click)="showSendCodeCard()">Enviar de nuevo el Código</a>
          </mat-hint>
        </mat-card-content>
        <mat-card-actions class="row">
          <button mat-flat-button class="col" color="primary" (click)="changePassword()">Reestablecer Contraseña</button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div>
</div>
