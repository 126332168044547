<div class="sidenav-right p-3">
  <div class="row">
    <div class="col-xs-10 sidenav-filter-title">
      <h2>Filtrar</h2>
    </div>
    <div class="col-xs-2 button-close">
      <button mat-icon-button (click)="closeSideNavFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="row pt-2">
    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>IDE</mat-label>
      <mat-chip-list #chipLisIde aria-label="Fruit selection">
        <mat-chip *ngFor="let ide of ideList" (removed)="removeIde(ide)">
          {{ide}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input placeholder="Agregar ide..."
               [matChipInputFor]="chipLisIde"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="addIde($event)">
      </mat-chip-list>
    </mat-form-field>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>IDV</mat-label>
      <mat-chip-list #chipLisIdv aria-label="Fruit selection">
        <mat-chip *ngFor="let idv of idvList" (removed)="removeIdv(idv)">
          {{idv}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input placeholder="Agregar idv..."
               [matChipInputFor]="chipLisIdv"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="addIdv($event)">
      </mat-chip-list>
    </mat-form-field>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Sitrap</mat-label>
      <mat-chip-list #chipLisSitrap aria-label="Fruit selection">
        <mat-chip
          *ngFor="let sitrap of sitrapSeleccionados"
          (removed)="removeSitrap(sitrap)">
          {{sitrap}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Seleccionar..."
          #sitrapInput
          [formControl]="sitrapCtrl"
          [matAutocomplete]="autoCompleteSitrap"
          [matChipInputFor]="chipLisSitrap"
        >
      </mat-chip-list>
      <mat-autocomplete #autoCompleteSitrap="matAutocomplete" (optionSelected)="selectedSitrap($event)">
        <mat-option *ngFor="let sitrap of sitrapFiltrados | async" [value]="sitrap">
          {{sitrap}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    
    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Propietario</mat-label>
      <mat-chip-list #chipListPropietario aria-label="Fruit selection">
        <mat-chip
          *ngFor="let propietario of propietariosSeleccionados"
          (removed)="removePropietario(propietario)">
          {{propietario.descripcion}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Seleccionar..."
          #propietarioInput
          [formControl]="propietarioCtrl"
          [matAutocomplete]="autoCompletePropietario"
          [matChipInputFor]="chipListPropietario"
        >
      </mat-chip-list>
      <mat-autocomplete #autoCompletePropietario="matAutocomplete" (optionSelected)="selectedPropietario($event)">
        <mat-option *ngFor="let propietario of propietariosFiltrados | async" [value]="propietario">
          {{propietario.descripcion}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Potrero</mat-label>
      <mat-chip-list #chipListPotrero aria-label="Fruit selection">
        <mat-chip
          *ngFor="let potrero of potrerosSeleccionados"
          (removed)="removePotrero(potrero)">
          {{potrero.descripcion}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Seleccionar..."
          #potreroInput
          [formControl]="potreroCtrl"
          [matAutocomplete]="autoCompletePotrero"
          [matChipInputFor]="chipListPotrero"
        >
      </mat-chip-list>
      <mat-autocomplete #autoCompletePotrero="matAutocomplete" (optionSelected)="selectedPotrero($event)">
        <mat-option *ngFor="let potrero of potrerosFiltrados | async" [value]="potrero">
          {{potrero.descripcion}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Categoría</mat-label>
      <mat-chip-list #chipListCategoria aria-label="Fruit selection">
        <mat-chip
          *ngFor="let categoria of categoriasSeleccionadas"
          (removed)="removeCategoria(categoria)">
          {{categoria.descripcion}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Seleccionar..."
          #categoriaInput
          [formControl]="categoriaCtrl"
          [matAutocomplete]="autoCompleteCategoria"
          [matChipInputFor]="chipListCategoria"
        >
      </mat-chip-list>
      <mat-autocomplete #autoCompleteCategoria="matAutocomplete" (optionSelected)="selectedCategoria($event)">
        <mat-option *ngFor="let categoria of categoriasFiltradas | async" [value]="categoria">
          {{categoria.descripcion}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Clasificación</mat-label>
      <mat-chip-list #chipLisClasificacion aria-label="Fruit selection">
        <mat-chip
          *ngFor="let clasificaciones of clasificacionesSeleccionadas"
          (removed)="removeClasificacion(clasificaciones)">
          {{clasificaciones}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Seleccionar..."
          #clasificacionInput
          [formControl]="clasificacionesCtrl"
          [matAutocomplete]="autoCompleteClasificacion"
          [matChipInputFor]="chipLisClasificacion"
        >
      </mat-chip-list>
      <mat-autocomplete #autoCompleteClasificacion="matAutocomplete" (optionSelected)="selectedClasificacion($event)">
        <mat-option *ngFor="let clasificaciones of clasificacionesFiltradas | async" [value]="clasificaciones">
          {{clasificaciones}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Carimbo</mat-label>
      <mat-chip-list #chipLisCarimbo aria-label="Fruit selection">
        <mat-chip *ngFor="let carimbo of carimbos" (removed)="removeCarimbo(carimbo)">
          {{carimbo.valor}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input type="text" placeholder="Agregar carimbo..."
               [matChipInputFor]="chipLisCarimbo"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="addCarimbo($event)">
      </mat-chip-list>
    </mat-form-field>

    <app-multidatepicker tabindex="-1"  mode="YEAR" label="Año de nacimiento" [(ngModel)]="anho"></app-multidatepicker><br>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Mes de nacimiento</mat-label>
      <mat-chip-list #chipListMes aria-label="Fruit selection">
        <mat-chip
          *ngFor="let mes of mesesSeleccionados"
          (removed)="removeMes(mes)">
          {{mes.descripcion}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Seleccionar..."
          #mesInput
          [formControl]="mesCtrl"
          [matAutocomplete]="autoCompleteMes"
          [matChipInputFor]="chipListMes"
        >
      </mat-chip-list>
      <mat-autocomplete #autoCompleteMes="matAutocomplete" (optionSelected)="selectedMes($event)">
        <mat-option *ngFor="let mes of mesesFiltrados | async" [value]="mes">
          {{mes.descripcion}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="segundolabel" appearance="outline">
      <mat-label class="label">Rango de fecha de nacimiento</mat-label>
      <mat-date-range-input class="input" [formGroup]="rangeForm" [rangePicker]="picker" [max]="maxDate">
        <input matStartDate formControlName="date_from" placeholder="Fecha desde">
        <input matEndDate formControlName="date_to" placeholder="Fecha hasta">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="rangeForm.controls.date_from.hasError('matStartDateInvalid')">Fecha inicio inválida</mat-error>
      <mat-error *ngIf="rangeForm.controls.date_to.hasError('matEndDateInvalid')">Fecha fin inválida</mat-error>
    </mat-form-field>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Lote</mat-label>
      <mat-chip-list #chipListLote aria-label="Fruit selection">
        <mat-chip
          *ngFor="let lote of lotesSeleccionados"
          (removed)="removeLote(lote)">
          {{lote.descripcion}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Seleccionar..."
          #loteInput
          [formControl]="loteCtrl"
          [matAutocomplete]="autoCompleteLote"
          [matChipInputFor]="chipListLote"
        >
      </mat-chip-list>
      <mat-autocomplete #autoCompleteLote="matAutocomplete" (optionSelected)="selectedLote($event)">
        <mat-option *ngFor="let lote of lotesFiltrados | async" [value]="lote">
          {{lote.descripcion}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Sexo</mat-label>
      <mat-chip-list #chipListSexo aria-label="Fruit selection">
        <mat-chip
          *ngFor="let sexo of sexosSeleccionados"
          (removed)="removeSexo(sexo)">
          {{sexo}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Seleccionar..."
          #sexoInput
          [formControl]="sexoCtrl"
          [matAutocomplete]="autoCompleteSexo"
          [matChipInputFor]="chipListSexo"
        >
      </mat-chip-list>
      <mat-autocomplete #autoCompleteSexo="matAutocomplete" (optionSelected)="selectedSexo($event)">
        <mat-option *ngFor="let sexo of sexosFiltrados | async" [value]="sexo">
          {{sexo}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Padre Toro Nac</mat-label>
      <mat-chip-list #chipLisPadre aria-label="Fruit selection">
        <mat-chip *ngFor="let padre of padres" (removed)="removePadre(padre)">
          {{padre}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input placeholder="Agregar nombre del padre..."
               [matChipInputFor]="chipLisPadre"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="addPadre($event)">
      </mat-chip-list>
    </mat-form-field>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Madre</mat-label>
      <mat-chip-list #chipListMadre_idv aria-label="Fruit selection">
        <mat-chip
          *ngFor="let madre_idv of madre_idvsSeleccionados"
          (removed)="removeMadre_idv(madre_idv)">
          {{madre_idv}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Seleccionar..."
          #madre_idvInput
          [formControl]="madre_idvCtrl"
          [matAutocomplete]="autoCompleteMadre_idv"
          [matChipInputFor]="chipListMadre_idv"
        >
      </mat-chip-list>
      <mat-autocomplete #autoCompleteMadre_idv="matAutocomplete" (optionSelected)="selectedMadre_idv($event)">
        <mat-option *ngFor="let madre_idv of madre_idvsFiltrados | async" [value]="madre_idv">
          {{madre_idv}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="example-chip-list" appearance="outline">
      <mat-label>Estados</mat-label>
      <mat-chip-list #chipListEstado aria-label="Fruit selection">
        <mat-chip
          *ngFor="let estado of estadosSeleccionados"
          (removed)="removeEstado(estado)">
          {{estado.descripcion}}
          <button matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Seleccionar..."
          #estadoInput
          [formControl]="estadoCtrl"
          [matAutocomplete]="autoCompleteEstado"
          [matChipInputFor]="chipListEstado"
        >
      </mat-chip-list>
      <mat-autocomplete #autoCompleteEstado="matAutocomplete" (optionSelected)="selectedEstado($event)">
        <mat-option *ngFor="let estado of estadosFiltrados | async" [value]="estado">
          {{estado.descripcion}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-card-actions class="filter-button-actions">
      <button class="filter-button" mat-flat-button color="accent" (click)="filtrar()">Filtrar</button>
    </mat-card-actions>
  </div>
</div>
